import React from 'react';
import { useIntl } from 'react-intl';

import { NavbarV2 as Navbar } from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import SEO from '../../components/SEO';
import Header from './Header';
import Meet from './Meet';
import SoundMoney from './SoundMoney';
import What from './What';
import Join from './Join';
import { appLocales } from '../../i18n';

const AboutUs = () => {
  const { messages, locale } = useIntl();

  return (
    <>
      <SEO page="about-us" />
      <div>
        <Navbar locale={locale} />
        <Header />
      </div>
      <Meet />
      <SoundMoney />
      <What />
      <Join />
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default AboutUs;
