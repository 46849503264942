import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import Download from '@bitcoin-portal/bitcoincom-pkg-components/dist/Download';
import Link from '@bitcoin-portal/bitcoincom-pkg-components/dist/Link';
import { useTheme } from 'styled-components';
import { Apps } from './styled';

import { sendGaEvent } from '../../../helpers/analytics';

const DownloadLinks = ({ center, bottom }) => {
  const { messages } = useIntl();
  const theme = useTheme();

  const trackDownload = (platform = 'Other Versions') => {
    sendGaEvent({
      category: 'Wallet',
      action: 'Click to download',
      label: platform,
    });
  };

  return (
    <div
      style={{
        textAlign: 'center',
        maxWidth: center ? '' : '450px',
        position: 'relative',
        zIndex: '0',
      }}
    >
      <Apps center={center}>
        <Download
          link={
            bottom
              ? 'https://apps.apple.com/us/app/bitcoin-wallet-by-bitcoin-com/id1252903728?ls=1'
              : messages['wallet.header.link.appstore']
          }
          type="ios"
          style={{ zIndex: '99' }}
          className="ga-wallet-cta"
          onClick={() => {
            trackDownload('iOS');
          }}
        />
        <Download
          link={
            bottom
              ? 'https://play.google.com/store/apps/details?id=com.bitcoin.mwallet'
              : messages['wallet.header.link.googleplay']
          }
          type="google"
          style={{ zIndex: '99' }}
          className="ga-wallet-cta"
          onClick={() => {
            trackDownload('Android');
          }}
        />
      </Apps>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link
          href={messages['wallet.header.link.other']}
          newTab
          style={{
            zIndex: '99',
            margin: center ? 'auto' : 0,
            marginTop: 16,
          }}
          color={theme.palette.text.tertiary}
          className="ga-wallet-cta"
          onClick={() => {
            trackDownload('Other Versions');
          }}
        >
          {messages['wallet.header.text.other']}
        </Link>
      </div>
    </div>
  );
};

DownloadLinks.propTypes = {
  center: PropTypes.bool,
  bottom: PropTypes.bool,
};

DownloadLinks.defaultProps = {
  center: false,
  bottom: false,
};

export default DownloadLinks;
