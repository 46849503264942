import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { NavbarV2 as Navbar } from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Markdown from '@bitcoin-portal/bitcoincom-pkg-components/dist/Markdown';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import { Wrapper, Title, StyledContentBlock, MenuWrapper } from './styled';
import SEO from './SEO';
import menuTabs from '../../../i18n/legal/menu';
import Menu from '../../../components/Menu';
import SideMenu from '../../../components/SideMenu';
import { appLocales } from '../../../i18n';

class UserAgreement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  render() {
    const { locale, data } = this.props;
    if (!data) return <></>;

    const { htmlAst, headings, frontmatter } = data.node;
    const { title, metatitle, metadescription, metaimage, metatwitter, date } =
      frontmatter;

    const menuItems = headings.reduce((prev, curr, i) => {
      const newItem = {
        name: curr.value,
        link: `${locale !== 'ja' ? `/${locale}` : ''}/legal/user-agreement/#${
          i + 1
        }`,
        hash: `#${i + 1}`,
      };
      return [...prev, newItem];
    }, []);

    let id = 1;
    const htmlChildren = htmlAst.children.reduce((prev, curr) => {
      if (curr.tagName === 'h2') {
        const updated = { ...curr, properties: { id: `${id}` } };
        id += 1;
        return [...prev, updated];
      }

      return [...prev, curr];
    }, []);

    htmlAst.children = htmlChildren;

    return (
      <>
        <SEO
          title={metatitle}
          description={metadescription}
          image={metaimage}
          twitter={metatwitter}
        />
        <Wrapper>
          <Navbar locale={locale} />
          <MenuWrapper>
            <Menu selectedTab={0} locale={locale} menuTabs={menuTabs[locale]} />
          </MenuWrapper>
          <Section>
            <ContentBlock>
              <Title>
                <H1>{title}</H1>
                <Paragraph style={{ textAlign: 'center' }}>{date}</Paragraph>
              </Title>
            </ContentBlock>
          </Section>
          <StyledContentBlock
            flip={StyledContentBlock.Flips.Horizontal}
            aside={<SideMenu items={menuItems} contentRef={this.contentRef} />}
          >
            <Markdown ref={this.contentRef} htmlAst={htmlAst} />
          </StyledContentBlock>
          <Footer
            locale={locale}
            localeList={Object.keys(appLocales)}
            cookieName="bitcoinjp-locale"
            defaultLocale="ja"
          />
        </Wrapper>
      </>
    );
  }
}

UserAgreement.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    edges: PropTypes.array,
    node: PropTypes.object,
  }).isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};

export default injectIntl(UserAgreement);
