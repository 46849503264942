import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import { useIntl, FormattedMessage } from 'react-intl';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import BuyWidgetV2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/BuyWidgetV2';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import {
  StyledContentBlock,
  Heading,
  ImageWrapper,
  MediaWrapper,
} from './styled';

// const VARIANT_IDS = {
//   1: '2105_fomo1',
//   2: '2105_fomo2'
// };

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const StyledBuyWidgetV2 = ({ locale }) => {
  return (
    <CenteredContainer>
      <BuyWidgetV2
        initialCrypto="BTC"
        selectedTab="buy"
        passThroughAddress
        locale={locale}
        callback={({ data }) => {
          const params = new URLSearchParams({
            ...(data.crypto && { crypto: data.crypto }),
            ...(data.fiat && { fiat: data.fiat }),
            ...(data.amount && { amount: data.amount }),
            ...(data.address && { address: data.address }),
            ...{ utm_source: 'bitcoin_www_gambling' },
            ...{ utm_medium: 'medium_www_gambling' },
            ...{ utm_campaign: 'campaign_www_gambling' },
          });
          if (parseFloat(data.amount) > 4999 && data.fiat === 'USD') {
            window.location.href =
              'https://branch.wallet.bitcoin.com/e/buy-widget-gambling-25k';
          } else {
            window.location.href = `https://branch.wallet.bitcoin.com/buy-widget-gambling?${params.toString()}`;
          }
        }}
      />
    </CenteredContainer>
  );
};

const Header = () => {
  const { messages, locale } = useIntl();

  const imgPath = messages['buy.header.image'].replace('-sm.png', '');

  return (
    <Section>
      <Heading>
        <H1>
          <FormattedMessage id="buy.header.title" />
        </H1>
        <Paragraph>
          <FormattedMessage id="buy.header.subtitle" />
        </Paragraph>
      </Heading>
      <StyledContentBlock aside={<StyledBuyWidgetV2 locale={locale} />}>
        <ImageWrapper>
          <Image
            maxWidth="unset"
            maxHeight="inherit"
            width="unset"
            src={messages['buy.header.image']}
            alt={messages['buy.header.alt']}
            sizes="(max-width: 767px) 260w, (max-width: 1199px) 327w, (min-width: 1200px) 450w"
            srcSet={`${imgPath}-sm.png 260w, ${imgPath}-md.png 327w, , ${imgPath}-lg.png 450w, ${imgPath}-sm@2x.png 520w, ${imgPath}-md@2x.png 655w, ${imgPath}-lg@2x.png 900w`}
          />
        </ImageWrapper>
      </StyledContentBlock>
      <MediaWrapper>
        <Image
          maxWidth="unset"
          maxHeight="unset"
          src={messages['home.header.media']}
          alt="media graphic"
          sizes="(max-width: 1199px) 994w, (min-width: 1200px) 1988w"
          srcSet="/images/uploads/homepage-media@1x.png 994w, /images/uploads/homepage-media@2x.png 1988w"
        />
      </MediaWrapper>
    </Section>
  );
};

StyledBuyWidgetV2.propTypes = {
  locale: PropTypes.string,
};

StyledBuyWidgetV2.defaultProps = {
  locale: 'ja',
};

export default Header;
