import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import BuyWidgetV2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/BuyWidgetV2';
import CreditCard from '@bitcoin-portal/bitcoincom-pkg-components/dist/IconsNavigation/CreditCard';
import H4 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H4';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import {
  StyledSection,
  Heading,
  Subheading,
  StyledButton,
  StyledText,
  ImageWrapper,
  StyledContentBlock,
} from './styled';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const StyledBuyWidgetV2 = ({ locale }) => {
  return (
    <CenteredContainer>
      <BuyWidgetV2
        initialCrypto="BTC"
        selectedTab="buy"
        passThroughAddress
        locale={locale}
        callback={({ data }) => {
          const params = new URLSearchParams({
            ...(data.crypto && { crypto: data.crypto }),
            ...(data.fiat && { fiat: data.fiat }),
            ...(data.amount && { amount: data.amount }),
            ...(data.address && { address: data.address }),
            ...{ utm_source: 'bitcoin_www_gambling' },
            ...{ utm_medium: 'medium_www_gambling' },
            ...{ utm_campaign: 'campaign_www_gambling' },
          });
          if (parseFloat(data.amount) > 4999 && data.fiat === 'USD') {
            window.location.href =
              'https://branch.wallet.bitcoin.com/e/buy-widget-gambling-25k';
          } else {
            window.location.href = `https://branch.wallet.bitcoin.com/buy-widget-gambling?${params.toString()}`;
          }
        }}
      />
    </CenteredContainer>
  );
};

const Buy = () => {
  const { messages, locale } = useIntl();

  const imgPath = messages['home.buy.image'].replace('-sm.png', '');

  return (
    <StyledSection>
      <Heading>
        <H4 style={{ marginBottom: 16 }}>
          <FormattedMessage id="home.buy.section-title" />
        </H4>
        <Subheading>
          <FormattedMessage id="home.buy.section-subtitle" />
        </Subheading>
        <StyledButton
          href={messages['home.buy.mobile-link']}
          design="secondary"
        >
          {messages['home.buy.mobile-buy']}
          <CreditCard
            height={24}
            style={{ alignSelf: 'center', marginLeft: '11px' }}
          />
        </StyledButton>
        <StyledText bold>{messages['home.buy.highlight']}</StyledText>
      </Heading>
      <StyledContentBlock
        className="homepage-buy"
        aside={<StyledBuyWidgetV2 locale={locale} />}
      >
        <ImageWrapper>
          <Image
            maxWidth="unset"
            maxHeight="inherit"
            src={messages['home.buy.image']}
            alt={messages['home.buy.image-alt']}
            sizes="(max-width: 767px) 260w, (max-width: 1199px) 327w, (min-width: 1200px) 450w"
            srcSet={`${imgPath}-sm.png 260w, ${imgPath}-md.png 327w, ${imgPath}-lg.png 450w, ${imgPath}-sm@2x.png 520w, ${imgPath}-md@2x.png 655w, ${imgPath}-lg@2x.png 900w`}
          />
        </ImageWrapper>
      </StyledContentBlock>
    </StyledSection>
  );
};

StyledBuyWidgetV2.propTypes = {
  locale: PropTypes.string,
};

StyledBuyWidgetV2.defaultProps = {
  locale: 'ja',
};

export default Buy;
