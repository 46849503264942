import React from 'react';
import { useIntl } from 'react-intl';

import { NavbarV2 as Navbar } from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Iframe from '@bitcoin-portal/bitcoincom-pkg-components/dist/Iframe';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import { Title, StyledContentBlock, MenuWrapper } from './styled';

import SEO from '../../../components/SEO';
import menuTabs from '../../../i18n/legal/menu';
import Menu from '../../../components/Menu';

import { appLocales } from '../../../i18n';

const DsarForm = () => {
  const { messages, locale } = useIntl();
  return (
    <>
      <SEO page="dsar-form" />
      <Navbar locale={locale} />
      <MenuWrapper>
        <Menu selectedTab={3} locale={locale} menuTabs={menuTabs[locale]} />
      </MenuWrapper>
      <Section>
        <ContentBlock>
          <Title>
            <H1>{messages['dsar-form.header.title']}</H1>
          </Title>
        </ContentBlock>
      </Section>
      <StyledContentBlock>
        <Iframe
          src={messages['dsar-form.form.src']}
          title={messages['dsar-form.form.src']}
        />
      </StyledContentBlock>
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default DsarForm;
