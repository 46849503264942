import React from 'react';
import { useIntl } from 'react-intl';

import { NavbarV2 as Navbar } from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import Iframe from '@bitcoin-portal/bitcoincom-pkg-components/dist/Iframe';
import SEO from '../../components/SEO';
import { appLocales } from '../../i18n';

const Map = () => {
  const { messages, locale } = useIntl();
  return (
    <>
      <SEO page="map" />
      <Navbar locale={locale} />
      <Iframe src="https://map.bitcoin.com" />
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default Map;
