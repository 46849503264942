import React from 'react';
import { useIntl } from 'react-intl';

import { NavbarV2 as Navbar } from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import SEO from '../../components/SEO';
import Header from './Header';
import ContactForm from './ContactForm';
import { appLocales } from '../../i18n';

const ContactUs = () => {
  const { messages, locale } = useIntl();
  return (
    <>
      <SEO page="contact-us" />
      <Navbar locale={locale} />
      <Header />
      <ContactForm />
      {/* <Banner
        title={messages['contact-us.banner.title']}
        subtitle={messages['contact-us.banner.text']}
        cta={{
          link: messages['contact-us.banner.button-link'],
          text: messages['contact-us.banner.button-text'],
          newTab: true,
        }}
      /> */}
      <Footer
        locale={locale}
        localeList={Object.keys(appLocales)}
        cookieName="bitcoinjp-locale"
        legal={messages['global.footer.legal']}
        links={messages['global.footer.links']}
        defaultLocale="ja"
      />
    </>
  );
};

export default ContactUs;
